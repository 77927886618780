export const isLocalhost = () => {
  return location.host.includes('localhost') || location.port != '';
};

export const isSynthetics = () => {
  if (typeof window === 'undefined') return false;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window._DATADOG_SYNTHETICS_BROWSER !== undefined;
};

export const isNetlifyPreview = () => {
  return location.host.includes('netlify.app');
};
