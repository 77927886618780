import { useEffect, useRef, useState } from 'react';

import {
  IFlutter,
  useFlutterWebview,
} from '@/components/hooks/flutter-webview';
import { ONLY_DEV } from '@/components/shared/utility/env';
import { styled } from '@/stitches.config';

const StyledBar = styled('div', {
  position: 'fixed',
  width: '100%',
  zIndex: 10000,
  left: 0,
  top: 0,
  opacity: 0.8,
  pointerEvents: 'none',
});

const LogVersion = styled('div', {
  padding: 5,
  position: 'fixed',
  width: '100%',
  background: '#000',
  color: '#fff',
  fontWeight: 'bold',
});
const LogContainerWrapper = styled('div', {
  left: 0,
  top: 34,
  position: 'relative',
  maxHeight: 60,
  overflowX: 'hidden',
  overflowY: 'auto',
  background: '#fff',
});
const LogContainer = styled('div', {
  padding: '0 5px',
});

declare global {
  interface Window {
    _td: any;
    _myid: any;
    _webview_debug: any;
  }
}

const DEBUG_WEBVIEW_EVENT = 'DEBUG_WEBVIEW_EVENT';

export const log = (message: string) => {
  if (!ONLY_DEV()) return;

  const now = new Date();
  window.dispatchEvent(
    new CustomEvent(DEBUG_WEBVIEW_EVENT, {
      detail: `${now.toLocaleString()}:${now.getUTCSeconds()} : ${message}`,
    })
  );
};

export const View: React.FunctionComponent = () => {
  const FLUTTER_DATA: IFlutter = useFlutterWebview();
  const [data, setData] = useState<(IFlutter | string)[]>([]);
  const viewRef = useRef(null);

  useEffect(() => {
    if (!FLUTTER_DATA) return;

    const _data = [...data, FLUTTER_DATA];
    setData(_data);
  }, [FLUTTER_DATA]);

  useEffect(() => {
    const onUpdate = (e) => {
      setData([...data, e.detail]);
    };

    window.addEventListener(DEBUG_WEBVIEW_EVENT, onUpdate);

    return () => {
      window.removeEventListener(DEBUG_WEBVIEW_EVENT, onUpdate);
    };
  }, [data]);

  useEffect(() => {
    if (!viewRef.current) return;
    if (!data) return;

    viewRef.current.scrollTop = viewRef.current.scrollHeight;
  }, [viewRef, data]);

  if (!ONLY_DEV()) return null;
  if (!FLUTTER_DATA) return null;
  if (!FLUTTER_DATA.my_id) return null;

  return (
    <>
      <StyledBar>
        <LogVersion>v.{process.env.NEXT_PUBLIC_COMMIT_ID}</LogVersion>
        <LogContainerWrapper ref={viewRef}>
          <LogContainer>
            {data.map((o, i) => (
              <p key={i}>{JSON.stringify(o)}</p>
            ))}
          </LogContainer>
        </LogContainerWrapper>
      </StyledBar>
    </>
  );
};

export default View;
