import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import useWindowLoad from '@/components/hooks/window-load';
import { isLocalhost, isSynthetics } from '@/components/shared/utility/host';
import { normalisePathname } from '@/components/shared/utility/router';

const IGNORE_ERROR_LIST = [
  'Script error.',
  'Illegal invocation',
  'Invariant: attempted to hard navigate to the same URL',
];

export const useRUMRecording = () => {
  const ROUTER = useRouter();
  const [rum, setRUM] = useState(null);

  useEffect(() => {
    if (!rum) return;
    const VIEW_NAME = normalisePathname(ROUTER.pathname);
    rum.startView({ name: VIEW_NAME });
  }, [rum, ROUTER]);

  useWindowLoad(() => {
    const init = async () => {
      if (isLocalhost()) return;
      if (isSynthetics()) return;

      const DATADOG_RUM = (await import('@datadog/browser-rum')).datadogRum;
      DATADOG_RUM.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
        env: process.env.NEXT_PUBLIC_ENV,
        allowedTracingUrls: [/https:\/\/.*\.isuzu-tis\.com/],
        version: process.env.NEXT_PUBLIC_COMMIT_ID,
        sessionSampleRate: +process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE,
        trackUserInteractions: true,
        sessionReplaySampleRate: 0,
        trackResources: true,
        trackLongTasks: true,
        trackViewsManually: true,
        beforeSend: (event) => {
          if (
            event.type === 'error' &&
            IGNORE_ERROR_LIST.some((o) => event.error.message.includes(o))
          ) {
            event.context = { ...event.context, ignoreError: 'true' };
          }
        },
      });

      setRUM(DATADOG_RUM);
    };
    init();
  });
};
