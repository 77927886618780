import { IWeb } from '@/types/config';

export const siteNameByWeb = (web: IWeb) => {
  switch (web) {
    case 'lcv':
      return 'ទំនាក់ទំនងមក​យើងខ្ញុំ​ - Isuzu TIS Cambodia';
  }
};

export const titleByWeb = (web: IWeb) => {
  switch (web) {
    case 'lcv':
      return 'ទំនាក់ទំនងមក​យើងខ្ញុំ​ - Isuzu TIS Cambodia';
  }
};

export const descriptionByWeb = (web: IWeb) => {
  switch (web) {
    case 'lcv':
      return 'ទំនាក់ទំនងមក​យើងខ្ញុំ​ - Isuzu TIS Cambodia';
  }
};

export const ogImageByWeb = (web: IWeb) => {
  switch (web) {
    case 'lcv':
      return 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ebe5359f8c9db3/62fb311dea0d864c464abcaf/OG_ISUZU.jpg';
  }
};
