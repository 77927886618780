import { useEffect } from 'react';

const useWindowLoad = (onLoadCallback) => {
  useEffect(() => {
    const handleLoad = () => {
      onLoadCallback();
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [onLoadCallback]);
};

export default useWindowLoad;
